export const openWeatherApiUrl = 'https://api.openweathermap.org/data/2.5/';
export const lat = 49.435;
export const lon = 18.789;
export const openWeatherApikey = '36e8a8371397c3192e7557c62b102ce2';
export const openWeatherUnits = 'metric';
export const openWeatherUrlWeather = `${openWeatherApiUrl}onecall?lat=${lat}&lon=${lon}&appid=${openWeatherApikey}&units=${openWeatherUnits}`;

export const dayLabel = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const dayLabelShorthand = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const feedsUrl = ['https://web.dev/articles/feed.xml', 'https://nuxt.com/blog/rss.xml', 'http://feeds.feedburner.com/vzhurudolu?format=xml', 'https://nerdy.dev/rss.xml'];
export const proxyUrl = 'https://proxy.freshidea.sk/';

export const firebaseConfig = {
    apiKey: 'AIzaSyDIGhZs860N7Or8TnqY57k_LALRUJ6virQ',
    authDomain: 'firefox-home-bf5dc.firebaseapp.com',
    databaseURL: 'https://firefox-home-bf5dc-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'firefox-home-bf5dc',
    storageBucket: 'firefox-home-bf5dc.appspot.com',
    messagingSenderId: '180852200225',
    appId: '1:180852200225:web:199d40e5f23febfc046460',
};

export const tiles = [
    {
        name: 'Synology Home',
        url: 'http://quickconnect.to/tiborpino',
        background: '#0DD399',
        color: '#fff',
    },
    {
        name: 'Messenger',
        url: 'https://www.messenger.com',
        background: '#0084ff',
        color: '#fff',
    },
    {
        name: 'iKros účtovníctvo',
        url: 'https://fakturacia.kros.sk/',
        background: '#0095da',
        color: '#fff',
    },
    {
        name: 'Moje dane',
        url: 'https://adisspr.mfcr.cz/pmd/home/prihlaseni-do-dis',
        background: '#ad194a',
        color: '#fff',
    },
    {
        name: 'Datové schránky',
        url: 'https://www.mojedatovaschranka.cz/as/login?type=captcha&uri=https%3A%2F%2Fwww.mojedatovaschranka.cz%2Fportal%2FISDS%2F',
        background: '#fcb712',
        color: '#fff',
    },
    {
        name: 'Fio banka',
        url: 'https://ib.fio.sk/ib/login',
        background: '#00458A',
        color: '#fff',
    },
    {
        name: 'Fronius',
        url: 'https://www.solarweb.com/PvSystems/PvSystem?pvSystemId=ff6c51d2-3bad-4cc1-8373-028caf1858e3',
        background: '#e2001a',
        color: '#fff',
    },
    {
        name: 'SSD portal',
        url: 'https://ims.ssd.sk/consumption-production/profile-data/',
        background: '#a80002',
        color: '#fff',
    },
    {
        name: 'Grid',
        url: 'http://grid.malven.co/',
        background: '#fff16ced',
        color: '#fff',
    },
    {
        name: 'Codepen',
        url: 'https://codepen.io/your-work',
        background: '#111111',
        color: '#fff',
    },
    {
        name: 'Trello',
        url: 'https://trello.com/u/tiborpino/boards',
        background: '#0c66e4',
        color: '#fff',
    },
];
