import React, { useEffect, useState } from 'react';
import Parser from 'rss-parser';
import useSWR from 'swr';
import Tab from './Tab';
import { feedsUrl, proxyUrl } from '../../global/config';
import firebase from '../../utils/firebase';
import Article from './Article';

const parser = new Parser();
const fetcher = () => Promise.all(feedsUrl.map((url) => parser.parseURL(`${proxyUrl}?url=${url}`)));
const dbKey = 'visited_links';

const Feed = () => {
    const [activeFeed, setActiveFeed] = useState(0);
    const [visitedLinks, setVisitedLinks] = useState([]);
    const { data: feeds } = useSWR(feedsUrl, fetcher);

    useEffect(() => {
        console.log(firebase.database().ref(dbKey));
        // set visited links
        firebase
            .database()
            .ref(dbKey)
            .on('value', (snapshot, error) => {
                // get date
                console.error(error);
                console.log(snapshot);
                const data = snapshot.val() || [];
                // save data
                setVisitedLinks(data);
            });
    }, []);

    const handleClickOnLink = (guid) => {
        // already saved?
        if (visitedLinks.includes(guid)) return;
        // clone data
        const data = [...visitedLinks];
        // save new item
        data.push(guid);
        // update state
        setVisitedLinks(data);
        // save to database
        firebase
            .database()
            .ref(dbKey)
            .set(data);
    };

    const getUnreadCount = (feedKey, index) => feeds[index].items.reduce((acc, item) => (visitedLinks.includes(item.guid) ? acc : acc + 1), 0);

    if (feeds === undefined) return <div className="bg-secondary rounded p-4 mt-6 text-center text-white">Loading feeds</div>;

    return (
        <div className="mt-md-6 mb-6 mb-md-0">
            <div className="row">
                <div className="col-12 col-lg-3 d-flex d-lg-block flex-nowrap overflow-auto" role="tablist">
                    {feeds.map((feed, index) => (
                        <Tab unreadCount={getUnreadCount(feed.link, index)} onClick={() => setActiveFeed(index)} label={feed.title} selected={index === activeFeed} key={`feed-${feed.feedUrl}`} />
                    ))}
                </div>
                <div className="col-12 col-lg-9">
                    <div aria-live="polite">
                        {feeds[activeFeed].items.map((item, index) => (
                            <Article {...item} contentEncoded={item['content:encoded'] || item.contentSnippet} handleClickOnLink={handleClickOnLink} isClicked={visitedLinks.includes(item.guid)} key={`feed-item-${item.guid}`} isFirst={index === 0} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Feed;
